<template>
    <div class="">

        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">Dashboard</h2>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a>Home</a>
                                </li>
                                <!-- <li class="breadcrumb-item"><a href="#">Pages</a>
                            </li>
                            <li class="breadcrumb-item active">FAQ
                            </li> -->
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="content-body">
            <h3 class="py-2">Welcome {{ userData.name }}!</h3>
            <section id="dashboard-ecommerce">
                <div class="row match-height">
                    <!-- Medal Card -->
                    <div class="col-xl-6 col-md-6 col-12">
                        <div class="card card-congratulation-medal d-none">
                            <div class="card-body">
                                <h5>Hi 🎉 {{ userData.name }}!</h5>
                                <!-- <p v-if="on_package" class="card-text font-small-3">You are on {{userData.package.title}}</p>

                                    <p v-else class="card-text font-small-3">You are not on a package please purchase a package</p> -->
                                <h3 class="mb-75 mt-2 pt-50">
                                    <a href="javascript:void(0);">${{ wallet_balance }}</a>
                                </h3>
                                '
                                <h3 :class="'mb-75 mt-2 pt-50'">
                                    <a href="javascript:void(0);">${{ multiple_wallet_balance }}</a>
                                </h3>


                                <button v-if="on_package" type="button" class="btn btn-primary ">{{
                                    userData.package.title
                                    }}</button>
                                <router-link v-else to="/User/Packages" type="button" class="btn btn-primary ">Get
                                    Started</router-link>
                                <img src="app-assets/images/illustration/badge.svg" class="congratulation-medal"
                                    alt="Medal Pic" />
                            </div>
                        </div>

                        <div class="card border-warning">
                            <div class="card-body">
                                <div class="text-center">
                                    <h6 class="text-center badge badge-primary"> Upline</h6>
                                </div>
                                <div class="car text-center">
                                    <div class="p-1">

                                        <div class="row">
                                            <div class="col-12">
                                                <img class="round" :src="upline_data.avatar" alt="avatar" height="40"
                                                    width="40">
                                            </div>
                                            <div class="col-12">
                                                <h6>{{ upline_data.name }}</h6>
                                                <!-- <h6>{{ upline_data.email }}</h6> -->




                                            </div>
                                        </div>

                                        <hr>
                                    </div>
                                </div>


                                <div class="text-center ">

                                    <div class="container ">


                                        <div class="row">
                                            <div class="col-6 mx-auto text-center">

                                                <div v-if="userData.package" class="v text-center ">
                                                    <img class=" package_icon" :src="userData.package.featured_logo"
                                                        alt="">

                                                    <h6 class="font-weigh-bold mt-1">{{ userData.package.title }}</h6>
                                                </div>
                                                <div v-else class="c text-center ">
                                                    <h6>No Subscriptions yet</h6>
                                                </div>


                                            </div>
                                            <div class="col-6 d-none py-2 text-center">
                                                <h6 class="text-muted">My Upline</h6>
                                                <img style="object-fit: cover; width: 70px; height: 70px; border-radius: 50%;"
                                                    class="shadow" :src="userData.avatar" alt="">
                                                <h6>Victor Asuquo</h6>
                                                <h6>PGN0000</h6>

                                            </div>
                                            <div class="col-6 mx-auto text-center pt-2 ">


                                                <h3 class="font-weight-bold mb-2 text-success">$ {{
                                                    formatDigit(wallet_balance / 500)
                                                    }}</h3>
                                                <h6 class="'text-center te ">Wallet Balance</h6>

                                                <div :class="userData.package_id == '1' ? 'mt-3' : 'd-none'">
                                                    <h3 :class="'font-weight-bold mb-2 text-success '">
                                                        ${{ formatDigit(multiple_wallet_balance / 500) }}</h3>
                                                    <h6 :class="'text-center te '">Multiple Wallet Balance</h6>


                                                </div>

                                            </div>
                                        </div>
                                        <div class="row">


                                            <div class="col-6 mx-auto">
                                                <h6 class="btn btn-primary btn-sm ">Package</h6>
                                            </div>
                                            <div class="col-6 mx-auto">
                                                <div class="form-group ">
                                                    <router-link to="/user/earnings" class="btn btn-primary btn-sm ">My
                                                        Wallet </router-link>
                                                </div>
                                            </div>

                                        </div>
                                    </div>





                                    <div class="container py-1 d-none">
                                        <div class="row">
                                            <div class="col-12 border">
                                                <h4 class="font-weight-bold">{{ direct_referrals.length }}</h4>
                                                <h6>Referrals</h6>
                                            </div>
                                            <div class="col-6 border d-none">
                                                <h4 class="font-weight-bold">0</h4>
                                                <h6>Organization</h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="text-center">
                                        <div class="form-group d-flex justify-content-start ">
                                            <input type="text"
                                                :value="'https://app.phoenixgn.com/register/' + referral_link" id="code"
                                                class="form-control form-control-sm text-center">
                                            <button @click="copy_referrer_code()"
                                                class="btn btn-sm btn-primary ">copy</button>
                                        </div>
                                    </div>

                                    <div class="container-fluid">
                                        <div class="row">
                                            <div class="col-4 col-sm-4 text-center mx-auto p-0">
                                                <a :href="'https://www.facebook.com/sharer/sharer.php?u=' + referral_link +
                                                    '&quote=Unlock unlimited opportunities and build generational wealth with a trusted platform. Start today!'"
                                                    class="btn btn-sm btn-block btn-outline-primary mb-1">
                                                    Facebook
                                                </a>
                                            </div>

                                            <div class="col-4 col-sm-4 text-center mx-auto p-0">
                                                <a :href="'https://twitter.com/intent/tweet?url=' + referral_link +
                                                    '&text=Financial freedom starts here! 🚀 Join a proven platform that opens doors to endless possibilities. Don’t wait—start building your legacy today!'"
                                                    class="btn btn-sm btn-block btn-outline-info mb-1">
                                                    Twitter
                                                </a>
                                            </div>

                                            <div class="col-4 col-sm-4 text-center p-0 mx-auto">
                                                <a :href="'https://wa.me/?text=🚀 Take charge of your future! Join a powerful platform that helps you build wealth and unlock endless opportunities. Sign up now: https%3A%2F%2Fapp.phoenixgn.com%2F%23%2Fregister%2F' + userData.usercode"
                                                    class="btn btn-sm btn-block btn-outline-success mb-1">
                                                    WhatsApp
                                                </a>
                                            </div>
                                        </div>


                                    </div>

                                </div>

                            </div>
                        </div>


                    </div>
                    <!--/ Medal Card -->

                    <!-- Statistics Card -->
                    <div class="col-xl-6 col-md-6 col-12">

                        <StairStepComponent></StairStepComponent>

                    </div>
                    <!--/ Statistics Card -->
                </div>






                <div v-if="accounts.length != 0" class="card">

                    <div class="card-body text-center">
                        <h6 class="text-center py-1 font-weight-bold">My Accounts </h6>

                        <div class="tf-tree">
                            <ul>
                                <li>
                                    <span class="tf-nc btn btn-success btn-sm">
                                        <img :src="userData.avatar" alt="">
                                        <span>{{ userData.usercode }}</span>
                                    </span>
                                    <ul>
                                        <li>
                                            <span class="tf-nc btn btn-primary btn-sm">
                                                <img :src="userData.accounts[0].user.avatar" alt="">
                                                <span>{{ userData.accounts[0].user.usercode }}</span>
                                            </span>

                                            <ul>
                                                <li>
                                                    <span class="tf-nc btn btn-primary btn-sm">
                                                        <img :src="userData.accounts[2].user.avatar" alt="">
                                                        <span>{{ userData.accounts[2].user.usercode }}</span>
                                                    </span>
                                                </li>
                                                <li>
                                                    <span class="tf-nc btn btn-primary btn-sm">
                                                        <img :src="userData.accounts[3].user.avatar" alt="">
                                                        <span>{{ userData.accounts[3].user.usercode }}</span>
                                                    </span>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <span class="tf-nc btn btn-primary btn-sm">
                                                <img :src="userData.accounts[1].user.avatar" alt="">
                                                <span>{{ userData.accounts[1].user.usercode }}</span>
                                            </span>

                                            <ul>
                                                <li>
                                                    <span class="tf-nc btn btn-primary btn-sm">
                                                        <img :src="userData.accounts[4].user.avatar" alt="">
                                                        <span>{{ userData.accounts[4].user.usercode }}</span>
                                                    </span>
                                                </li>
                                                <li>
                                                    <span class="tf-nc btn btn-primary btn-sm">
                                                        <img :src="userData.accounts[5].user.avatar" alt="">
                                                        <span>{{ userData.accounts[5].user.usercode }}</span>
                                                    </span>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="row match-height">
                    <!-- Company Table Card -->
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="card card-company-table">
                            <div class="card-body ">
                                <div class="table-responsive">
                                    <h6>Activity Log</h6>

                                    <div v-for="notification in notifications" :key="notification.index"
                                        class="card border-primary mb-1 bg-primar text-white">
                                        <div style="padding: 4px 7px;" class="container-fluid row">
                                            <div class="col-md-7">
                                                <h6 class="text-warning">🔔 {{ notification.title }}</h6>
                                                <p>
                                                    {{ notification.log }}
                                                </p>
                                            </div>
                                            <div class="col-md-5">
                                                <span class="text-white font-italic">{{
                                                    formatDate(notification.created_at) }}</span>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="py-2 text-center">
                                        <router-link to="/user/notifications" class="btn btn-sm btn-primary">view all
                                            notifications</router-link>
                                    </div>





                                    <table class="table d-none">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Contact</th>
                                                <th>Date</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>#</td>
                                                <td>Name</td>
                                                <td>Contact</td>
                                                <td>Date</td>
                                                <td>Status</td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--/ Company Table Card -->

                    <!-- Developer Meetup Card -->
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="card card-developer-meetup">
                            <div class="card-header">

                            </div>
                            <div class="card-body">
                                <h6>Top 5 Recruiters</h6>
                                <hr>

                                <div style="height: 270px; overflow-y: scroll;">

                                    <div v-for="earner in top_earners" :key="earner.id" class="card bg-primary mb-1">
                                        <div class="card-bod container-fluid py-1">
                                            <div class="row">
                                                <div class="col-2 ">

                                                    <img :src="earner.users.avatar" class="leader-avatar" alt="">
                                                </div>
                                                <div class="col-7">

                                                    <h6 class="ml-1 mt-2">{{ earner.users.name }}</h6>
                                                </div>
                                                <div class="col-3 ">

                                                    <h6 class="mt-2 float-right">🏆</h6>
                                                </div>
                                            </div>


                                        </div>
                                    </div>

                                </div>



                                <h6>Top 5 Earners</h6>
                                <hr>

                                <div style="height: 270px; overflow-y: scroll;">

                                    <div v-for="recuit in top_recuiters" :key="recuit.id" class="card bg-success mb-1">
                                        <div class="card-bod container-fluid py-1">
                                            <div class="row">
                                                <div class="col-2 ">

                                                    <img :src="recuit.users.avatar" class="leader-avatar" alt="">
                                                </div>
                                                <div class="col-7">

                                                    <h6 class="ml-1 mt-2">{{ recuit.users.name }}</h6>
                                                </div>
                                                <div class="col-3 ">

                                                    <h6 class="mt-2 float-right">🏆</h6>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                    <!--/ Developer Meetup Card -->


                </div>

                <div class="row match-height d-none">
                    <div class="col-md-6 col-xl-3">
                        <div class="card shadow-none bg-transparent border-primary">
                            <div class="card-body">
                                <h2 class="card-tite">$0.00</h2>
                                <h4 class="card-title">Direct Referral</h4>
                                <a class="card-text">Details</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-3">
                        <div class="card shadow-none bg-transparent border-warning">
                            <div class="card-body">
                                <h2 class="card-tite">$0.00</h2>
                                <h4 class="card-title">Indirect Referral</h4>
                                <a class="card-text">Details</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-xl-3">
                        <div class="card shadow-none bg-transparent border-success">
                            <div class="card-body">
                                <h2 class="card-tite">$0.00</h2>
                                <h4 class="card-title">3 in 1</h4>
                                <a class="card-text">Details</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-xl-3">
                        <div class="card shadow-none bg-transparent border-info">
                            <div class="card-body">
                                <h2 class="card-tite">$0.00</h2>
                                <h4 class="card-title">Matching Pair</h4>

                                <a class="card-text">Details</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-xl-3">
                        <div class="card shadow-none bg-transparent border-danger">
                            <div class="card-body">
                                <h2 class="card-tite">$0.00</h2>
                                <h4 class="card-title">Phoenix Reward</h4>
                                <a class="card-text">Details</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-xl-3">
                        <div class="card shadow-none bg-transparent border-white">
                            <div class="card-body">
                                <h2 class="card-tite">$0.00</h2>
                                <h4 class="card-title">Leadership</h4>
                                <a class="card-text">Details</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-xl-3">
                        <div class="card shadow-none bg-transparent border-dark">
                            <div class="card-body">
                                <h2 class="card-tite">$0.00</h2>
                                <h4 class="card-title">Product Reorder</h4>
                                <a class="card-text">Details</a>
                            </div>
                        </div>
                    </div>


                    <div class="col-md-6 col-xl-3">
                        <div class="card shadow-none bg-transparent border-success">
                            <div class="card-body">
                                <h2 class="card-tite">$0.00</h2>
                                <h4 class="card-title">Stair Step</h4>
                                <a class="card-text">Details</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-xl-6">
                        <div class="card shadow-none bg-transparent border-warning">
                            <div class="card-body">
                                <h2 class="card-tite">$0.00</h2>
                                <h4 class="card-title">Profit Sharing</h4>
                                <a class="card-text">Details</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-xl-6">
                        <div class="card shadow-none bg-transparent border-primary">
                            <div class="card-body">
                                <h2 class="card-tite">$0.00</h2>
                                <h4 class="card-title">Royalty Bonus</h4>
                                <a class="card-text">Details</a>
                            </div>
                        </div>
                    </div>



                </div>



            </section>



            <!-- Button trigger modal -->
            <button type="button" class="btn btn-primary d-none" id="packageButton" data-toggle="modal"
                data-target="#exampleModal">
                Launch demo modal
            </button>

            <!-- Modal -->
            <div class="modal fade show" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <!-- https://assets10.lottiefiles.com/packages/lf20_1pxqjqps.json -->
                        <div class="modal-body d-flex justify-content-center">

                            <div class="c">


                                <lottie-player src="https://assets1.lottiefiles.com/private_files/lf30_o8qeq21m.json"
                                    background="transparent" speed="1" style="min-width: 300px; height: 300px;" loop
                                    autoplay></lottie-player>

                                <div class="e py-2 px-md-3">

                                    <h3 class="text-center">Congratulations, Great opportunities await you!!</h3>
                                    <h3 class="text-center">Subscribe to a Package to start earning.</h3>


                                </div>

                                <div class="c text-center py-3">
                                    <router-link to="/user/packages" class=" btn btn-warning btn-lg">GET
                                        STARTED</router-link>

                                </div>

                            </div>



                        </div>

                    </div>
                </div>
            </div>

            <button type="button" class="btn btn-primary d-none" id="phoenixButton" data-toggle="modal"
                data-target="#exampleModal2">
                Launch demo modal
            </button>

            <!-- Modal -->
            <div class="modal fade show" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <!-- https://assets10.lottiefiles.com/packages/lf20_1pxqjqps.json -->
                        <div class="modal-body d-flex justify-content-center">

                            <div class="c">


                                <lottie-player src="https://assets1.lottiefiles.com/private_files/lf30_o8qeq21m.json"
                                    background="transparent" speed="1" style="min-width: 300px; height: 300px;" loop
                                    autoplay></lottie-player>

                                <div class="e py-2 px-md-3">

                                    <h3 class="text-center">Congratulations, Phoenix Reward Awaits You!!</h3>



                                </div>

                                <div class="c text-center py-3">
                                    <button @click="claimPhoenixReward" class=" btn btn-warning btn-lg">CLAIM
                                        REWARD</button>

                                </div>

                            </div>



                        </div>

                    </div>
                </div>
            </div>



        </div>

    </div>
</template>


<script>
import axios from 'axios'
import StairStepComponent from '../../components/StairStepComponent.vue';


export default {

    components: {
        // paystack,
        StairStepComponent
    },



    data() {
        return {

            wallet_balance: 0,

            multiple_wallet_balance: 0,

            userData: [],

            referral_link: '',

            on_package: false,

            phoenix_reward_elligible: false,

            direct_referrals: '',

            jade_left_count: 0,

            jade_left_count_percent: 0,

            jade_right_count_percent: 0,

            jade_right_count: 0,

            notifications: [],

            jade_record: [],

            accounts: [],

            top_earners: [],

            top_recuiters: [],

            userStairStep: [],

            director_left_count: 0,
            director_right_count: 0,
            zircon_left_count: 0,
            zircon_right_count: 0,
            onyx_left_count: 0,
            onyx_right_count: 0,
            opal_left_count: 0,
            opal_right_count: 0,


            upline_data: ''
        }
    },

    methods: {
        formatDigit(digits) {

            return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(digits)
        },

        formatDate(dateString) {

            var date = new Date(dateString);

            var options = {
                month: 'long',
                year: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: true
            };



            return date.toLocaleDateString(undefined, options);
        },

        get_wallet_balance() {

            axios({
                method: "get",
                url: process.env.VUE_APP_URL + '/api/wallet_balance',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                },

            })
                .then((response) => {

                    console.log(response)

                    this.wallet_balance = response.data.wallet_balance
                })
                .catch((response) => {

                    console.log(response)
                })
        },

        get_multiple_wallet_balance() {

            axios({
                method: "get",
                url: process.env.VUE_APP_URL + '/api/multiple_wallet_balance',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                },

            })
                .then((response) => {

                    console.log(response)

                    this.multiple_wallet_balance = response.data.wallet_balance

                    // alert(this.multiple_wallet_balance)
                })
                .catch((response) => {

                    console.log(response)
                })
        },

        getUserData() {

            axios({
                url: process.env.VUE_APP_URL + '/api/user_stats',
                method: 'get',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                },


            })
                .then((response) => {


                    console.log(response)

                    if (response.data.user.package) {

                        this.on_package = true

                        console.log(this.on_package)

                    }

                    this.userData = response.data.user

                    this.accounts = response.data.user.accounts

                    this.direct_referrals = response.data.direct_referrals

                    this.notifications = response.data.notifications

                    this.upline_data = response.data.upline ?? ''


                    console.log(this.on_package)
                    console.log(response)
                    console.log(response.data.user.usercode)
                    this.referral_link = (response.data.user.usercode)


                    return this.referral_link;
                })
                .catch((response) => {

                    console.log(response)
                })


        },

        copy_referrer_code() {

            var copyText = document.getElementById("code");
            copyText.select();
            copyText.setSelectionRange(0, 99999)
            document.execCommand("copy");
            this.$vToastify.success({
                'body': 'Refferral Code Copied',
                'iconEnabled': true,
                'defaultTitle': false,
                'hideProgressbar': true
            });
            // alert("Copied the text: " + copyText.value);
        },

        checkSubscription() {
            // const user = JSON.parse(localStorage.getItem('user_data'))
            // if (!this.on_package) {

            //     document.getElementById('packageButton').click()
            // }

            axios({
                url: process.env.VUE_APP_URL + '/api/user_stats',
                method: 'get',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                },


            })
                .then((response) => {

                    if (response.data.user.package) {

                        this.on_package = true

                        console.log(this.on_package)

                    } else {
                        console.log(this.on_package)
                        document.getElementById('packageButton').click()
                    }


                    console.log(this.on_package)
                    console.log(response)
                })
                .catch((response) => {

                    console.log(response)
                })
        },

        checkPhoenixReward() {
            // const user = JSON.parse(localStorage.getItem('user_data'))
            // if (!this.on_package) {

            //     document.getElementById('packageButton').click()
            // }

            axios({
                url: process.env.VUE_APP_URL + '/api/phoenix_reward',
                method: 'get',
                params: {
                    check_modal_status: true
                },
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                },


            })
                .then((response) => {

                    if (response.data.modal_status) {

                        this.phoenix_reward_elligible = true

                        // alert('y')

                        document.getElementById('phoenixButton').click()

                        console.log(this.phoenix_reward_elligible)

                    } else {

                        // alert('n')

                        console.log(this.phoenix_reward_elligible)

                    }


                })
                .catch((response) => {

                    console.log(response)
                })
        },

        claimPhoenixReward() {
            // const user = JSON.parse(localStorage.getItem('user_data'))
            // if (!this.on_package) {

            //     document.getElementById('packageButton').click()
            // }

            axios({
                url: process.env.VUE_APP_URL + '/api/phoenix_reward',
                method: 'get',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                },


            })
                .then((response) => {

                    if (localStorage.getItem('reloadedp')) {
                        // The page was just reloaded. Clear the value from local storage
                        // so that it will reload the next time this page is visited.
                        localStorage.removeItem('reloadedp');
                    } else {
                        // Set a flag so that we know not to reload the page twice.
                        localStorage.setItem('reloadedp', '1');
                        location.reload();
                    }

                    console.log(response)

                    // return this.$router.push('/user/notifications')


                })
                .catch((response) => {

                    console.log(response)
                })
        },

        checkJade() {

            axios({
                url: process.env.VUE_APP_URL + '/api/jade',
                method: 'post',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                },


            })
                .then((response) => {

                    // alert('jade check');

                    this.jade_record = response.data.record

                    this.jade_left_count = response.data.record.left_count
                    this.jade_left_count_percent = (response.data.record.left_count / 5 * 100)
                    this.jade_right_count_percent = (response.data.record.right_count / 5 * 100)
                    this.jade_right_count = response.data.record.right_count

                    console.log(response)

                })
                .catch((response) => {

                    console.log(response)
                })

        },

        jadeStar() {

            // alert('jade star check')



            axios({
                url: process.env.VUE_APP_URL + '/api/jade_star',
                method: 'post',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                },


            })
                .then((response) => {

                    // alert('jade star checked');

                    // this.jade_record = response.data.record

                    // this.jade_left_count = response.data.record.left_count
                    // this.jade_left_count_percent = (response.data.record.left_count/5 * 100)
                    // this.jade_right_count_percent = (response.data.record.right_count/5 * 100)
                    // this.jade_right_count = response.data.record.right_count

                    console.log(response)

                })
                .catch((response) => {

                    console.log(response)
                })

        },

        leaderBoard() {
            axios({
                method: "get",
                url: process.env.VUE_APP_URL + '/api/leaderboard',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                },

            })
                .then((response) => {

                    console.log(response)

                    this.top_earners = response.data.top_earners

                    this.top_recuiters = response.data.top_recuiters


                })
                .catch((response) => {

                    console.log(response)
                })
        },
        getStairStepData() {

            axios({
                url: process.env.VUE_APP_URL + '/api/userStairSteps',
                method: 'get',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                }
            }).then((response) => {

                this.userStairStep = response.data

                this.director_left_count = response.data.director?.left_count
                this.director_right_count = response.data.director?.right_count

                this.zircon_left_count = response.data.zircon?.left_count
                this.zircon_right_count = response.data.zircon?.right_count

                this.onyx_left_count = response.data.onyx?.left_count
                this.onyx_right_count = response.data.onyx?.right_count

                this.opal_left_count = response.data.opal?.left_count
                // alert(this.opal_left_count)
                this.opal_right_count = response.data.opal?.right_count


                console.log(response)
            }).catch((err) => {
                console.log(err)
            })
        }




    },

    mounted() {

        this.getUserData()

        // this.checkJade()

        // this.jadeStar()

        this.get_wallet_balance()

        this.get_multiple_wallet_balance()

        this.checkSubscription()

        this.checkPhoenixReward()

        this.leaderBoard()

        this.getStairStepData()


    },

}
</script>


<style scoped>
.leader-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.package_icon {

    height: 60px;
}

.tf-nc img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;


}

.tf-nc:before,
.tf-nc:after {
    /* css here */
    border: 2px solid white;
    border-radius: 56px;
}

li li:before {
    /* css here */

    border: 2px solid white;
    border-radius: 56px;
}
</style>
