<template >
    <div>
            <div class="main-menu-content">
            <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">

                <li class=" navigation-header">
                    <span>User Dashboard</span>
                    
                </li>

                <li class=" nav-item active">
                    <router-link class="d-flex align-items-center" to="/User/Home">
                      <i class="fa-solid fa-gauge"></i>
                        <span class="menu-title text-truncate" data-i18n="File Manager">Dashboard</span>
                    </router-link>
                </li>
               
                <!-- <li class=" nav-item"><a class="d-flex align-items-center" href="#"><i data-feather="file-text"></i><span class="menu-title text-truncate" data-i18n="Invoice">Invoice</span></a>
                    <ul class="menu-content">
                        <li><a class="d-flex align-items-center" href="app-invoice-list.html"><i data-feather="circle"></i><span class="menu-item text-truncate" data-i18n="List">List</span></a>
                        </li>
                        <li><a class="d-flex align-items-center" href="app-invoice-preview.html"><i data-feather="circle"></i><span class="menu-item text-truncate" data-i18n="Preview">Preview</span></a>
                        </li>
                        <li><a class="d-flex align-items-center" href="app-invoice-edit.html"><i data-feather="circle"></i><span class="menu-item text-truncate" data-i18n="Edit">Edit</span></a>
                        </li>
                        <li><a class="d-flex align-items-center" href="app-invoice-add.html"><i data-feather="circle"></i><span class="menu-item text-truncate" data-i18n="Add">Add</span></a>
                        </li>
                    </ul>
                </li> -->

                <!-- <li class=" nav-item ">
                    <router-link class="d-flex align-items-center" to="/User/News">
                      <i class="fa-solid fa-newspaper"></i>
                        <span class="menu-title text-truncate" data-i18n="File Manager">Knowledge Center</span>
                    </router-link>
                </li> -->

                <li class=" nav-item ">
                    <router-link class="d-flex align-items-center" to="/User/Packages">
                      <i class="fa-solid fa-box-open"></i>
                        <span class="menu-title text-truncate" data-i18n="File Manager">Packages</span>
                    </router-link>
                </li>

                <li class=" nav-item d-none">
                    <router-link class="d-flex align-items-center" to="/User/Investments">
                      <i class="fa-solid fa-piggy-bank"></i>
                        <span class="menu-title text-truncate" data-i18n="File Manager">Investments</span>
                    </router-link>
                </li>

   

                <li class=" navigation-header">
                    <span>My Account</span>
                    
                </li>

                <li class=" nav-item ">
                    <router-link class="d-flex align-items-center" to="/User/Profile">
                      <i class="fa-solid fa-id-card"></i>
                        <span class="menu-title text-truncate" data-i18n="File Manager">My Profile</span>
                    </router-link>
                </li>

                <li class=" nav-item ">
                    <router-link class="d-flex align-items-center" to="/User/Earnings">
                      <i class="fa-solid fa-wallet"></i>
                        <span class="menu-title text-truncate" data-i18n="File Manager">My Earnings</span>
                    </router-link>
                </li>

                <li class=" nav-item ">
                    <router-link class="d-flex align-items-center" to="/User/Transactions">
                      <i class="fa-solid fa-wallet"></i>
                        <span class="menu-title text-truncate" data-i18n="File Manager">My Transactions</span>
                    </router-link>
                </li>

                <li class=" nav-item ">
                    <router-link class="d-flex align-items-center" to="/leads">
                      <i class="fa-solid fa-users"></i>
                        <span class="menu-title text-truncate" data-i18n="File Manager">My Leads  </span>
                    </router-link>
                </li>

                <li class=" nav-item ">
                    <router-link class="d-flex align-items-center" to="/User/Genealogy">
                      <i class="fa-solid fa-sitemap"></i>
                        <span class="menu-title text-truncate" data-i18n="File Manager">Genealogy</span>
                    </router-link>
                </li>

                <li class=" nav-item ">
                    <router-link class="d-flex align-items-center" to="/User/Notifications">
                      <i class="fa-solid fa-bell"></i>
                        <span class="menu-title text-truncate" data-i18n="File Manager">Notifications</span>
                    </router-link>
                </li>

                    <li class=" navigation-header">
                    <span>Resources</span>
                    
                </li>

                <li class=" nav-item ">
                    <router-link class="d-flex align-items-center" to="/User/Store">
                      <i class="fa-solid fa-store"></i>
                        <span class="menu-title text-truncate" data-i18n="File Manager">PGN Stores</span>
                    </router-link>
                </li>

                <!-- <li class=" nav-item ">
                    <router-link class="d-flex align-items-center" to="/User/StairSteps">
                      <i data-feather="save"></i>
                        <span class="menu-title text-truncate" data-i18n="File Manager">Stair Steps</span>
                    </router-link>
                </li> -->


                <!-- <li class=" nav-item ">
                    <router-link class="d-flex align-items-center" to="/User/FAQ">
                      <i data-feather="save"></i>
                        <span class="menu-title text-truncate" data-i18n="File Manager">FAQ</span>
                    </router-link>
                </li> -->




                <!-- <li class=" nav-item ">
                
                    <router-link class="d-flex align-items-center" to="/login">
                      <i data-feather="save"></i>
                        <span class="menu-title text-truncate" data-i18n="Logout">Logout</span>
                    </router-link>
                </li> -->
              
               
            </ul>
        </div>

    </div>
</template>
<script>
export default {
    name: 'UserSidebar',
    components:{

    },
    data() {
        return {
            
        }
    },

    methods: {
        collapseDrawer(){
            
        }
    },
}
</script>