<template>
    <div class="">

        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">Dashboard</h2>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="index.html">Earnings</a>
                                </li>
                                <!-- <li class="breadcrumb-item"><a href="#">Pages</a>
                            </li>
                            <li class="breadcrumb-item active">FAQ
                            </li> -->
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="content-body">


            <div class="car">
                <div class="card-bod">

                    <div class="row">

                        <div class="col-md-6 py-2">
                            <div class="card">
                                <div class="card-header">Primary Wallet</div>
                                <div class="card-body">
                                    <h4 class="text-success">$ {{ formatDigits(wallet_balance / 500) }}</h4>
                                    <h4 class="text-warning">Total </h4>
                                </div>
                            </div>
                        </div>


                        <div class="col-md-6 py-2">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="text-danger">$ {{ formatDigits(total_debit / 500) }}</h4>
                                    <h4 class="text-danger">Total Withdrawal</h4>
                                    <router-link :to="'/withdrawals'"
                                        class="btn btn-sm btn-primary">Withdrawal</router-link>
                                </div>
                            </div>
                        </div>

                    </div>



                    <ul class="nav nav-pills mb-3 nav-fill" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home"
                                role="tab" aria-controls="pills-home" aria-selected="true">Commissions</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile"
                                role="tab" aria-controls="pills-profile" aria-selected="false">Stair Steps</a>
                        </li>
                        <li class="nav-item d-none" role="presentation">
                            <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact"
                                role="tab" aria-controls="pills-contact" aria-selected="false">Transactions</a>
                        </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                            aria-labelledby="pills-home-tab">

                            <div class="accordion" id="accordionExample">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left   " type="button"
                                                data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                                                aria-controls="collapseOne">
                                                <h6 class="text-warning">Direct Referral</h6>
                                                <h4>Total Balance: $ {{ formatDigits(direct_referral_sum / 500) }}</h4>
                                            </button>
                                        </h2>
                                    </div>


                                    <!-- second_gen_indr_sum
                                    third_gen_indr_sum
                                    three_one_sum -->

                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                        data-parent="#accordionExample">
                                        <div class="card-body table-responsive">
                                            <p class="py-1">
                                                Direct Referral Commission means the commission you earn when you
                                                personally refer anyone into Phoenixgn.
                                            </p>
                                            <div style="max-height: 230px; overflow: scroll;" class="c">
                                                <table class="table table-striped table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Account</th>

                                                            <th>Package</th>
                                                            <th>Bonus</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody v-if="direct_referrals.length > 0">
                                                        <tr v-for="direct_referral, key in direct_referrals" :key="key">
                                                            <td>{{ key + 1 }}</td>
                                                            <td>
                                                                {{ direct_referral.referree.name }} <br>
                                                                {{ direct_referral.referree.email }} <br>
                                                                <span class="badge badge-primary">{{
                                                                    direct_referral.referree.usercode
                                                                    }}</span>
                                                            </td>


                                                            <td>
                                                                <!-- <img style="height: 30px; min-widht: 30px;" class="shadow" :src="direct_referral.
                                                            .package.featured_logo??''" alt=""> <br> -->
                                                                {{ direct_referral.referree.package ?
                                                                    direct_referral.referree.package.title : ''
                                                                }}

                                                            </td>
                                                            <td>$ <span class="text-white">{{
                                                                formatDigits(direct_referral.referrer_bonus / 500)
                                                                    }}</span>
                                                            </td>


                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingTwo">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left collapsed" type="button"
                                                data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                                                aria-controls="collapseTwo">
                                                <h6 class="text-warning">Indirect Referral</h6>
                                                <h4>Total Balance: $ {{ formatDigits((second_gen_indr_sum +
                                                    third_gen_indr_sum) / 500)
                                                    }}</h4>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                        data-parent="#accordionExample">
                                        <div class="card-body table-responsive">
                                            <p class="py-1">
                                                Indirect Referral Commission means the commission you earn when those
                                                you refer also refer another members into Phoenix.
                                            </p>
                                            <div v-if="loading" class="py-5">
                                                <h4 class="text-white text-center mt-5">Loading...</h4>
                                            </div>
                                            <div v-else class="c">

                                                <h4 class="font-weight-bold py-3">Second Generation</h4>
                                                <table class="table table-striped table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Account</th>

                                                            <th>Package</th>
                                                            <th>Bonus</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody style="max-height: 230px; overflow: scroll;"
                                                        v-if="second_gen_indr_data.length > 0">
                                                        <tr v-for="second_gen_indr, key in second_gen_indr_data"
                                                            :key="key">
                                                            <td>{{ key + 1 }}</td>
                                                            <td>
                                                                {{ second_gen_indr.referral.name }} <br>
                                                                {{ second_gen_indr.referral.email }} <br>
                                                                <span class="badge badge-primary">{{
                                                                    second_gen_indr.referral.usercode
                                                                    }}</span>
                                                            </td>


                                                            <td>
                                                                <!-- <img style="height: 30px; min-widht: 30px;" class="shadow" :src="second_gen_indr.referral.package.featured_logo??''" alt=""> <br> -->
                                                                {{ second_gen_indr.referral.package.title }}

                                                            </td>
                                                            <td>$ <span class="text-white">{{
                                                                formatDigits((second_gen_indr.bonus) / 500)
                                                                    }}</span>
                                                            </td>


                                                        </tr>
                                                    </tbody>
                                                </table>


                                                <h4 class="font-weight-bold py-3">Third Generation</h4>

                                                <table class="table table-striped table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Account</th>

                                                            <th>Package</th>
                                                            <th>Bonus</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody style="max-height: 230px; overflow: scroll;"
                                                        v-if="third_gen_indr_data.length > 0">
                                                        <tr v-for="third_gen_indr, key in third_gen_indr_data"
                                                            :key="key">
                                                            <td>{{ key + 1 }}</td>
                                                            <td>
                                                                {{ third_gen_indr.referral.name }} <br>
                                                                {{ third_gen_indr.referral.email }} <br>
                                                                <span class="badge badge-primary">{{
                                                                    third_gen_indr.referral.usercode
                                                                    }}</span>
                                                            </td>


                                                            <td>
                                                                <!-- <img style="height: 30px; min-widht: 30px;" class="shadow" :src="third_gen_indr.referral.package.featured_logo??''" alt=""> <br> -->
                                                                {{ third_gen_indr.referral.package.title }}

                                                            </td>
                                                            <td>$ <span class="text-white">{{
                                                                formatDigits((third_gen_indr.bonus) / 500)
                                                                    }}</span>
                                                            </td>


                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left collapsed" type="button"
                                                data-toggle="collapse" data-target="#collapseThree"
                                                aria-expanded="false" aria-controls="collapseThree">
                                                <h6 class="text-warning">3 in 1 Commission</h6>
                                                <h4>Total Balance: $ {{ formatDigits(three_one_sum / 500) }}</h4>

                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                        data-parent="#accordionExample">
                                        <div class="card-body table-responsive">

                                            <p class="py-1">
                                                3 in 1 commission means the commission you earn when you register 3
                                                members in 7 days.
                                            </p>


                                            <table class="table table-striped table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>1st Account</th>

                                                        <th>2nd Account</th>

                                                        <th>3rd Account</th>

                                                        <th>Date</th>


                                                        <th>Bonus</th>

                                                    </tr>
                                                </thead>
                                                <tbody style="max-height: 230px; overflow: scroll;"
                                                    v-if="three_one_data.length > 0">
                                                    <tr v-for="three_one, key in three_one_data" :key="key">
                                                        <td>{{ key + 1 }}</td>
                                                        <td>
                                                            {{ three_one.first_referral.name }} <br>
                                                            {{ three_one.first_referral.email }} <br>
                                                            <span class="badge badge-primary">{{
                                                                three_one.first_referral.usercode
                                                                }}</span>
                                                            <br>
                                                            <!-- <img style="height: 30px; min-widht: 30px;" class="shadow" :src="three_one.first_referral.package.featured_logo??''" alt=""> <br> -->
                                                            {{ three_one.first_referral.package ?
                                                                three_one.first_referral.package.title : 'no-package'
                                                            }}
                                                        </td>

                                                        <td>
                                                            {{ three_one.second_referral.name }} <br>
                                                            {{ three_one.second_referral.email }} <br>
                                                            <span class="badge badge-primary">{{
                                                                three_one.second_referral.usercode
                                                                }}</span>
                                                            <br>
                                                            <!-- <img style="height: 30px; min-widht: 30px;" class="shadow" :src="three_one.second_referral.package.featured_logo??''" alt=""> <br> -->
                                                            {{ three_one.second_referral.package ?
                                                                three_one.second_referral.package.title : 'no-package'
                                                            }}
                                                        </td>

                                                        <td>
                                                            {{ three_one.third_referral.name }} <br>
                                                            {{ three_one.third_referral.email }} <br>
                                                            <span class="badge badge-primary">{{
                                                                three_one.third_referral.usercode
                                                                }}</span>
                                                            <br>
                                                            <!-- <img style="height: 30px; min-widht: 30px;" class="shadow" :src="three_one.third_referral.package.featured_logo??''" alt=""> <br> -->
                                                            {{ three_one.third_referral.package.title }}
                                                        </td>

                                                        <td>{{ three_one.created_at }}</td>

                                                        <td>$ <span class="text-white">{{
                                                            formatDigits((three_one.indirect_bonus) / 500)
                                                                }}</span>
                                                        </td>


                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingFour">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left collapsed" type="button"
                                                data-toggle="collapse" data-target="#collapseFour" aria-expanded="false"
                                                aria-controls="collapseFour">
                                                <h6 class="text-warning">Matching Pair Commission</h6>
                                                <h4>Total Balance: $ {{ formatDigits((current_points * 15) / 500) }}
                                                </h4>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour"
                                        data-parent="#accordionExample">
                                        <p class="p-5">
                                            This is the commission you earn when a package on the left pair with another
                                            on the right.
                                        </p>
                                        <div v-if="current_points == null ? '' : 'd-none'" class="card-body">



                                            <div class="con text-center">

                                                <div class="tf-tree">
                                                    <ul>
                                                        <li>
                                                            <span class="tf-nc btn btn-primary ">USERCODE</span>
                                                            <ul>
                                                                <li style="max-width: 200px;"><span
                                                                        class="tf-nc btn btn-primary ">LEFT PAIR</span>
                                                                </li>
                                                                <li style="max-width: 200px;"><span
                                                                        class="tf-nc btn btn-primary ">RIGHT PAIR</span>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>

                                            <div style="font-size: 97%;" class="">
                                                <div class="row">
                                                    <div class="col-6 border-right-primary ">
                                                        <div class="card table-responsive">
                                                            <div style="max-height: 300px; overflow: scroll;"
                                                                class="card-body table-responsive ">
                                                                <table class="table table-striped">
                                                                    <thead>
                                                                        <tr>

                                                                            <th>Accounts</th>

                                                                            <th class="d-none d-md-block d-md-none">
                                                                                Point</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody v-if="left_generations.length > 0">
                                                                        <tr v-for="left_generation, key in left_generations"
                                                                            :key="key">
                                                                            <td>
                                                                                {{ left_generation.users.usercode }}
                                                                                <br>
                                                                                {{ left_generation.users.email }} <br>
                                                                                <!-- <img style="height: 30px; min-widht: 30px;" class="shadow" :src="left_generation.packages.featured_logo??''" alt=""> <br> -->
                                                                                {{ left_generation.packages.title }}
                                                                            </td>

                                                                            <td class="">
                                                                                {{ left_generation.packages.points }}
                                                                            </td>

                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div class="col-6 border-left-primary ">
                                                        <div class="card table-responsive">
                                                            <div style="max-height: 300px; overflow: scroll;"
                                                                class="card-body table-responsive">
                                                                <table class="table table-striped">
                                                                    <thead>
                                                                        <tr>

                                                                            <th>Accounts</th>

                                                                            <th class="d-none d-md-block d-md-none">
                                                                                Point</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody v-if="right_generations.length > 0">
                                                                        <tr v-for="right_generation, key in right_generations"
                                                                            :key="key">
                                                                            <td>
                                                                                {{ right_generation.users.usercode }}
                                                                                <br>
                                                                                {{ right_generation.users.email }} <br>
                                                                                <!-- <img style="height: 30px; min-widht: 30px;" class="shadow" :src="right_generation.packages.featured_logo??''" alt=""> <br> -->
                                                                                {{ right_generation.packages.title }}
                                                                            </td>

                                                                            <td class="">
                                                                                {{ right_generation.packages.points }}
                                                                            </td>

                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>




                                        </div>
                                        <div style="height: 130px;" class="card-body py-3 text-center">

                                            <h6>No records found...</h6>

                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingFour">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left collapsed" type="button"
                                                data-toggle="collapse" data-target="#collapseFour" aria-expanded="false"
                                                aria-controls="collapseFour">
                                                <h6 class="text-warning">Phoenix Reward</h6>
                                                <h4>Total Balance: ${{ formatDigits((phoenix_rewards_sum) / 500) }}</h4>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour"
                                        data-parent="#accordionExample">
                                        <div class="card-body table-responsive">
                                            <p class="py-2">
                                                Every member that register with a Diamond package is entitled to weekly
                                                commission of $1. while Family package are entitled to weekly commission
                                                of $3 every Sunday.
                                            </p>
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Date</th>
                                                        <th>Week No.</th>
                                                        <th>Bonus</th>
                                                        <th>Status</th>


                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="phoenix_reward, key in phoenix_rewards"
                                                        :key="phoenix_reward.id">
                                                        <td>{{ key + 1 }}</td>
                                                        <td>{{ phoenix_reward.created_at }}</td>
                                                        <td>{{ phoenix_reward.weekInYear }}</td>
                                                        <td>{{ phoenix_reward.bonus }}</td>
                                                        <td>
                                                            <span class="badge badge-primary">paid</span>
                                                        </td>


                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header" id="headingFive">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left collapsed" type="button"
                                                data-toggle="collapse" data-target="#collapseFive" aria-expanded="false"
                                                aria-controls="collapseFive">
                                                <h6 class="text-warning">Product Re-order Commission</h6>
                                                <h4>Total Balance: 0</h4>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFive" class="collapse" aria-labelledby="headingFive"
                                        data-parent="#accordionExample">
                                        <div class="card-body table-responsive">
                                            <p class="py-2">
                                                Product Re-Order Commission for Family Pro-Max
                                                Package is the commission you earn from phoenixgn whenever you and the
                                                members of
                                                your team from your 1st generation down to your 10th generations
                                                purchase products
                                                for Family Pro-Max Package
                                            </p>
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Date</th>
                                                        <th>Week No.</th>
                                                        <th>Bonus</th>
                                                        <th>Status</th>


                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>


                                <div class="card">
                                    <div class="card-header" id="headingSeven">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left collapsed" type="button"
                                                data-toggle="collapse" data-target="#collapseSeven"
                                                aria-expanded="false" aria-controls="collapseSeven">
                                                <h6 class="text-warning">Stair-Step Commission</h6>
                                                <h4>Total Balance: 0</h4>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven"
                                        data-parent="#accordionExample">
                                        <div class="card-body table-responsive">
                                            <p class="py-2">
                                                Stair-Step Commission is the commission you earn from
                                                phoenixgn whenever you attain status and hierarchy at phoenixgn. To
                                                attain these
                                                status and hierarchies, you have to build and develop your direct
                                                referrals for them to
                                                attain their own status and hierarchy, and by so doing you will achieve
                                                yours.
                                            </p>
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Date</th>
                                                        <th>Week No.</th>
                                                        <th>Bonus</th>
                                                        <th>Status</th>


                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>


                                <div class="card">
                                    <div class="card-header" id="headingEight">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left collapsed" type="button"
                                                data-toggle="collapse" data-target="#collapseEight"
                                                aria-expanded="false" aria-controls="collapseEight">
                                                <h6 class="text-warning">Profit Sharing</h6>
                                                <h4>Total Balance: 0</h4>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseEight" class="collapse" aria-labelledby="headingEight"
                                        data-parent="#accordionExample">
                                        <div class="card-body table-responsive">
                                            <p class="py-2">
                                                Profit Sharing is the sharing you receive from phoenixgn at the end of
                                                every year, exactly on the 31st of December.
                                            </p>
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Date</th>
                                                        <th>Week No.</th>
                                                        <th>Bonus</th>
                                                        <th>Status</th>


                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>


                                <div class="card">
                                    <div class="card-header" id="headingNine">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left collapsed" type="button"
                                                data-toggle="collapse" data-target="#collapseNine" aria-expanded="false"
                                                aria-controls="collapseNine">
                                                <h6 class="text-warning">Royalty Bonus</h6>
                                                <h4>Total Balance: 0</h4>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseNine" class="collapse" aria-labelledby="headingNine"
                                        data-parent="#accordionExample">
                                        <div class="card-body table-responsive">
                                            <p class="py-2">
                                                Profit Sharing is the sharing you receive from phoenixgn at the end of
                                                every year, exactly on the 31st of December.
                                            </p>
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Date</th>
                                                        <th>Week No.</th>
                                                        <th>Bonus</th>
                                                        <th>Status</th>


                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>


                                <div class="card">
                                    <div class="card-header" id="headingTen">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left collapsed" type="button"
                                                data-toggle="collapse" data-target="#collapseTen" aria-expanded="false"
                                                aria-controls="collapseTen">
                                                <h6 class="text-warning">Leadership Bonus</h6>
                                                <h4>Total Balance: 0</h4>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTen" class="collapse" aria-labelledby="headingTen"
                                        data-parent="#accordionExample">
                                        <div class="card-body table-responsive">
                                            <p class="py-2">
                                                Leadership Bonus is the bonus you receive from phoenixgn when
                                                you start developing your direct leg members to become and achieve the
                                                EXECUTIVE,
                                                NATIONAL AND GLOBAL TEAM LEADER TITLE
                                            </p>
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Date</th>
                                                        <th>Week No.</th>
                                                        <th>Bonus</th>
                                                        <th>Status</th>


                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>


                                <div class="card">
                                    <div class="card-header" id="headingEleven">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left collapsed" type="button"
                                                data-toggle="collapse" data-target="#collapseEleven"
                                                aria-expanded="false" aria-controls="collapseEleven">
                                                <h6 class="text-warning">Network Development Bonus</h6>
                                                <h4>Total Balance: 0</h4>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseEleven" class="collapse" aria-labelledby="headingEleven"
                                        data-parent="#accordionExample">
                                        <div class="card-body table-responsive">
                                            <p class="py-2">
                                                Network Development Bonus is the bonus you
                                                receive from phoenixgn when you start registering distributors
                                                personally outside from
                                                your country base and they also start registering too. This bonus goes
                                                down to your 4th
                                                generations.
                                            </p>
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Date</th>
                                                        <th>Week No.</th>
                                                        <th>Bonus</th>
                                                        <th>Status</th>


                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>


                                <div class="card">
                                    <div class="card-header" id="headingTwelve">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left collapsed" type="button"
                                                data-toggle="collapse" data-target="#collapseTwelve"
                                                aria-expanded="false" aria-controls="collapseTwelve">
                                                <h6 class="text-warning">Retirement Bonus</h6>
                                                <h4>Total Balance: 0</h4>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTwelve" class="collapse" aria-labelledby="headingTwelve"
                                        data-parent="#accordionExample">
                                        <div class="card-body table-responsive">
                                            <p class="py-2">
                                                Retirement Bonus is the bonus that is been paid to you by
                                                Phoenixgn from the product both you and your downline from 1st
                                                generation down to
                                                your 10th generation purchases
                                            </p>
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Date</th>
                                                        <th>Week No.</th>
                                                        <th>Bonus</th>
                                                        <th>Status</th>


                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-profile" role="tabpanel"
                            aria-labelledby="pills-profile-tab">
                            <div class="card-body text-center">

                                <StairStepComponent></StairStepComponent>
                            </div>


                        </div>
                        <div class="tab-pane fade d-none" id="pills-contact" role="tabpanel"
                            aria-labelledby="pills-contact-tab">


                            <div class="card">
                                <div class="card-body">
                                    <h4 class="text-center mt-5">All Transaction Records</h4>
                                </div>
                            </div>



                        </div>
                    </div>



                </div>
            </div>

        </div>

    </div>


</template>

<script>
import axios from 'axios'
import StairStepComponent from '../../components/StairStepComponent.vue';

export default {
    components: {
        // paystack,
        StairStepComponent
    },
    data() {
        return {
            direct_referrals: [],
            second_gen_indr_data: [],
            third_gen_indr_data: [],
            three_one_data: [],
            wallet_balance: '',
            total_debit: '',
            userData: '',
            userStairStep: '',
            direct_referral_sum: '',
            second_gen_indr_sum: '',
            third_gen_indr_sum: '',
            three_one_sum: '',

            left_generations: [],
            right_generations: [],
            total_left_points: '',
            total_right_points: '',
            current_points: null,

            loading: false,

            phoenix_rewards: [],

            director_left_count: 0,
            director_right_count: 0,
            zircon_left_count: 0,
            zircon_right_count: 0,
            onyx_left_count: 0,
            onyx_right_count: 0,
            opal_left_count: 0,
            opal_right_count: 0,

            phoenix_rewards_sum: 0
        }
    },
    methods: {


        formatDigits(digits) {

            return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(digits)
        },

        getTransactions() {
            axios({
                url: process.env.VUE_APP_URL + '/api/wallet_balance',
                method: 'get',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                }
            })
                .then((response) => {

                    console.log(response)

                    this.transactions = response.data.user_wallet.data

                    this.links = response.data.user_wallet.links

                    this.wallet_balance = response.data.wallet_balance

                    this.total_debit = response.data.total_debit

                    this.direct_referrals = response.data.direct_referrals
                    this.second_gen_indr_data = response.data.second_gen_indr_data
                    this.third_gen_indr_data = response.data.third_gen_indr_data
                    this.three_one_data = response.data.three_one_data

                    this.direct_referral_sum = response.data.direct_referral_sum
                    this.second_gen_indr_sum = response.data.second_gen_indr_sum
                    this.third_gen_indr_sum = response.data.third_gen_indr_sum
                    this.three_one_sum = response.data.three_one_sum

                    if (response.data.genealogy_data) {

                        var genealogy_data = response.data.genealogy_data

                        this.left_generations = genealogy_data.left_generations

                        this.right_generations = genealogy_data.right_generations

                        this.total_left_points = genealogy_data.total_left_points

                        this.total_right_points = genealogy_data.total_right_points

                        this.current_points = genealogy_data.current_points


                    }



                    console.log(response)
                })
                .catch((response) => {

                    console.log(response)
                })
        },
        getPhoenixReward() {

            axios({
                url: process.env.VUE_APP_URL + '/api/phoenix_reward',
                method: 'get',
                params: {
                    get_data: true
                },
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                },


            })
                .then((response) => {

                    console.log(response)

                    this.phoenix_rewards = response.data.records

                    this.phoenix_rewards_sum = response.data.sum




                })
                .catch((response) => {

                    console.log(response)
                })
        },
        getUserData() {

            axios({
                url: process.env.VUE_APP_URL + '/api/user_stats',
                method: 'get',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                },


            })
                .then((response) => {


                    console.log(response)


                    this.userData = response.data.user


                    console.log(response)


                })
                .catch((response) => {

                    console.log(response)
                })


        },
        getStairStepData() {

            axios({
                url: process.env.VUE_APP_URL + '/api/userStairSteps',
                method: 'get',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                }
            }).then((response) => {

                this.userStairStep = response.data

                this.director_left_count = response.data.director.left_count
                this.director_right_count = response.data.director.right_count

                this.zircon_left_count = response.data.zircon.left_count
                this.zircon_right_count = response.data.zircon.right_count

                this.onyx_left_count = response.data.onyx.left_count
                this.onyx_right_count = response.data.onyx.right_count

                this.opal_left_count = response.data.opal.left_count
                this.opal_right_count = response.data.opal.right_count


                console.log(response)
            }).catch((err) => {
                console.log(err)
            })
        }

    },

    mounted() {

        this.getTransactions()
        this.getPhoenixReward()
        this.getUserData()
        this.getStairStepData()


    },
}
</script>

<style scoped>
.package_icon {

    height: 60px;
}
</style>
