<template>
    <div class="">

        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">Admin</h2>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="index.html">Members</a>
                                </li>
                                <!-- <li class="breadcrumb-item"><a href="#">Pages</a>
                            </li>
                            <li class="breadcrumb-item active">FAQ
                            </li> -->
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="content-body">

            <div class="container py">
                <div class="col-md-10 mx-auto">
                    <div class="card">
                        <div class="card-body">
                            <div class="form-group d-flex justify-content-start">
                                <input v-model="query_key" type="text" class="form-control" placeholder="Search">
                                <button @click="searchMembers()" class="btn btn-primary btn-sm">search</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="container table-responsive">


                <table class="table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Upline</th>

                            <th>Name</th>
                            <th>OTP</th>

                            <th>Package</th>
                            <th>Date</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody style="max-height: 300px; overflow: scroll;">
                        <tr v-for="user, key in users" :key="key">
                            <td>{{ key + 1 }}</td>
                            <td :title="user.referrer ? user.referrer.referrer.email : ''">
                                <img style="object-fit: cover; width: 50px; height: 50px; border-radius: 50%;"
                                    class="shadow" :src="user.referrer ? user.referrer.referrer.avatar : ''" alt=""><br>
                                {{ user.referrer ? user.referrer.referrer.usercode : '' }} <br>
                                {{ user.referrer ? user.referrer.referrer.email : '' }} <br>
                                {{ user.referrer ? user.referrer.referrer.name : '' }} <br>


                            </td>

                            <td>
                                <img style="object-fit: cover; width: 50px; height: 50px; border-radius: 50%;"
                                    class="shadow" :src="user.avatar" alt=""><br>
                                {{ user.usercode }}<br>
                                {{ user.email }}<br>
                                {{ user.name }}
                            </td>

                            <td><span class="badge badge-primary">{{ user.otp }}</span></td>


                            <td v-if="user.package">


                                <img style="min-width: 70px; height: 50px; object-fit: cover; " class="shadow"
                                    :src="user.package.featured_logo" alt=""> <br>

                                {{ user.package.title }}
                            </td>
                            <td v-else>

                                <h6>no package</h6>
                            </td>
                            <td>{{ user.created_at }}</td>
                            <td>
                                <button @click="viewUser(user.id)" type="button" class="btn btn-primary"
                                    data-toggle="modal" data-target="#exampleModal">
                                    Details
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- Button trigger modal -->


                <!-- Modal -->
                <div class="modal fade " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel"></h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">

                                <div class="container">


                                    <div class=" ">
                                        <div class="row">
                                            <div class="col-md-12 ">
                                                <div class="form-group mx-auto text-center">





                                                    <img onclick="document.getElementById('customFile').click()"
                                                        id="previewImg2"
                                                        style="height: 100px; width: 100px; object-fit: cover; border-radius: 50%;"
                                                        class="shadow border border-primary " :src="user_data.avatar">



                                                </div>

                                                <div class="text-center d-none">
                                                    <input @change="previewFile4" ref="file" type="file"
                                                        id="customFile">
                                                </div>

                                            </div>

                                            <div class="col-md-12 px-md-5 text-center">
                                                <div class="form-group">
                                                    <button @click="uploadAvatar()"
                                                        class="btn-primary btn btn-sm ">Upload</button>
                                                </div>
                                            </div>

                                        </div>

                                    </div>


                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="" for="">Name</label>
                                                <input :value="user_data.name" type="text" class="form-control">
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="" for="">Email Address</label>
                                                <input :value="user_data.name" type="text" class="form-control"
                                                    disabled>
                                            </div>

                                        </div>
                                    </div>


                                    <div class="row ">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="" for="">Phone</label>
                                                <input v-model="phone" type="text" class="form-control">
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="" for="">Gender</label>
                                                <input v-model="gender" type="text" class="form-control">
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="" for="">Date of Birth</label>
                                                <input v-model="dob" type="text" class="form-control">
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="" for="">Address</label>
                                                <input v-model="residential_address" type="text" class="form-control">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="" for="">Next of Kin Name: </label>
                                                <input type="text" v-model="nok_name" class="form-control"
                                                    placeholder="Next of Kin Name">
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="" for="">Next of Kin Email</label>
                                                <input type="text" v-model="nok_email" class="form-control"
                                                    placeholder="Next of Kin Email">
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="" for="">Next of Kin Phone</label>
                                                <input type="text" v-model="nok_phone" class="form-control">
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="" for="">Next of Kin Address</label>
                                                <input type="text" v-model="nok_address" class="form-control"
                                                    placeholder="Next of Kin Address">
                                            </div>
                                        </div>


                                    </div>

                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">Activate Package</h5>

                                    </div>



                                    <div v-if="user_data.payment_proof" class="col-md-6 mx-auto py-5">


                                        <div v-if="user_data.payment_proof.status == 'active'">
                                            <div class="">
                                                <div class="col-md-12 ">
                                                    <div class="form-group mx-auto text-center">





                                                        <img id="previewImg2"
                                                            style="height: 200px; width: 200px; object-fit: cover;"
                                                            class="shadow border border-primary "
                                                            :src="user_data.payment_proof.payment_proof">



                                                    </div>

                                                    <div class="text-center d-none">
                                                        <input @change="previewFile4" ref="file" type="file"
                                                            id="customFile">
                                                    </div>

                                                </div>

                                                <div class="col-md-12 px-md-5 text-center">
                                                    <h6 class="text-center">Proof of Payment</h6>

                                                    <h6>{{ user_data.payment_proof.package.title }}</h6>
                                                </div>

                                            </div>

                                            <div class="form-group">
                                                <button v-if="activating" class="btn btn-primary btn-block"
                                                    disabled>Activating...</button>

                                                <button v-else
                                                    @click="activate_package(user_data.id, user_data.payment_proof.package_id)"
                                                    class="btn btn-primary btn-block">Activate Package</button>
                                            </div>

                                        </div>

                                        <div v-else class="c">


dsfd
                                            <div v-if="user_data.status != 'multiple' && user_data.package_id == 1"
                                                class="c text-center py-5">

                                                <button v-if="wait" class="btn btn-warning" disabled>⏳ Activating...
                                                    please wait</button>

                                                <button v-else @click="register_accounts(user_data.id)"
                                                    class="btn btn-warning">Activate Multiple Accounts</button>

                                            </div>

                                            <div v-if="user_data.status == 'multiple' && user_data.package_id == 8"
                                                class="c text-center py-5">

                                                <button v-if="wait" class="btn btn-warning" disabled>⏳ Activating...
                                                    please wait</button>

                                                <button v-else @click="register_fpm_accounts(user_data.id)"
                                                    class="btn btn-warning">Activate Multiple Diamond Pro Max Accounts</button>

                                            </div>




                                        </div>

                                    </div>



                                    <div v-else class="c">






                                    </div>













                                    <div class="col-md-12 mx-auto d-none">

                                        <button v-if="loading" class="btn btn-primary btn-lg ">Updating...</button>

                                        <button v-else @click="updateProfile()"
                                            class="btn btn-primary btn-lg  ">Save</button>

                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            users: [],

            user_data: [],

            residential_address: '',
            phone: '',
            gender: '',
            nok_name: '',
            nok_email: '',
            nok_phone: '',
            nok_address: '',
            nin: '',
            dob: '',
            avatar: '',

            name: '',
            email: '',
            loading: false,

            query_key: '',

            activating: false,

            wait: false
        }
    },

    methods: {

        searchMembers() {


            axios({
                url: process.env.VUE_APP_URL + '/api/profiles',
                method: 'get',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                },
                params: {
                    query_key: this.query_key
                }

            })
                .then((response) => {

                    // loader.hide()


                    console.log(response)

                    this.users = response.data
                })
                .catch((response) => {

                    // loader.hide()

                    console.log(response)
                })

        },



        previewFile4(event) {


            console.log(event)

            if (event.target.files.length > 0) {
                var src = URL.createObjectURL(event.target.files[0]);

                document.getElementById("previewImg").classList.add('d-none')
                document.getElementById("previewImg2").classList.remove('d-none')
                var preview = document.getElementById("previewImg2");
                preview.src = src;

                // preview.style.display = "block";

                this.avatar = this.$refs.file.files[0];


            }

        },

        viewUser(index) {

            console.log(index)

            this.user_data =
                this.users.filter(element =>
                    (element.id == index))

            this.user_data = this.user_data[0]

            this.residential_address = this.user_data.profile?.residential_address
            this.phone = this.user_data.profile?.phone
            this.gender = this.user_data.profile?.gender
            this.nok_name = this.user_data.profile?.nok_name
            this.nok_email = this.user_data.profile?.nok_email
            this.nok_phone = this.user_data.profile?.nok_phone
            this.nok_address = this.user_data.profile?.nok_address
            this.nin = this.user_data.profile?.nin
            this.dob = this.user_data.profile?.dob

            this.avatar = this.user_data.avatar
            this.name = this.user_data.name
            this.email = this.user_data.email

            console.log(this.user_data)

            return this.user_data;

        },

        getImgUrl(url) {
            return url;
        },
        getUsers() {


            //  let loader = this.$loading.show({
            //         // Optional parameters
            //         container: this.fullPage ? null : this.$refs.formContainer,
            //         canCancel: false,
            //         onCancel: this.onCancel,
            //         color: '#6CC3EC',
            //         loader: 'bars',
            //         opacity: 0.3
            //     });


            axios({
                url: process.env.VUE_APP_URL + '/api/profiles',
                method: 'get',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                },
                params: {
                    type: 'all'
                }

            })
                .then((response) => {

                    // loader.hide()


                    console.log(response)

                    this.users = response.data
                })
                .catch((response) => {

                    // loader.hide()

                    console.log(response)
                })

        },

        activate_package(user, packagex) {
            // alert(user)
            // alert(packagex)


            this.activating = true


            axios({
                method: "post",
                url: process.env.VUE_APP_URL + '/api/activate_user_package',
                data: {
                    user_id: user,
                    package_id: packagex
                },
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                },

            })
                .then((response) => {

                    this.activating = false

                    this.$vToastify.success({
                        'body': '🎉 Package Activated Successfully!!',
                        'iconEnabled': true,
                        'defaultTitle': false,
                        'hideProgressbar': true
                    });


                    if (localStorage.getItem('reloadeda')) {
                        // The page was just reloaded. Clear the value from local storage
                        // so that it will reload the next time this page is visited.
                        localStorage.removeItem('reloadeda');
                    } else {
                        // Set a flag so that we know not to reload the page twice.
                        localStorage.setItem('reloadeda', '1');
                        // location.reload();
                    }



                    //            let d = document.getElementById('exampleModal')
                    //             d.style.display = "none"
                    //             // d.close()

                    //  this.getUsers()

                    console.log(response)
                })
                .catch((response) => {

                    this.activating = false

                    console.log(response)
                })
        },

        register_accounts(user_id) {

            this.wait = true

            axios({
                url: process.env.VUE_APP_URL + '/api/register_accounts',
                method: 'post',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                },
                data: {
                    user_id: user_id
                }

            })
                .then((response) => {

                    this.wait = false

                    // loader.hide()


                    console.log(response)

                    this.users = response.data

                    if (localStorage.getItem('reloadedx')) {
                        // The page was just reloaded. Clear the value from local storage
                        // so that it will reload the next time this page is visited.
                        localStorage.removeItem('reloadedx');
                    } else {
                        // Set a flag so that we know not to reload the page twice.9989887
                        localStorage.setItem('reloadedx', '1');
                        location.reload();
                    }
                })
                .catch((response) => {

                    // loader.hide()

                    console.log(response)
                })

        },

        register_fpm_accounts(user_id) {

            this.wait = true

            axios({
                url: process.env.VUE_APP_URL + '/api/register_fpm_accounts',
                method: 'post',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                },
                data: {
                    user_id: user_id
                }

            })
                .then((response) => {

                    this.wait = false

                    // loader.hide()


                    console.log(response)

                    this.users = response.data

                
                })
                .catch((response) => {

                    // loader.hide()

                    console.log(response)
                })

        }
    },

    mounted() {
        this.getUsers()
    },
}
</script>