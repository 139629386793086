<template >
    <div>
        <section id="pricing-plan">
            <!-- title text and switch button -->
            <div class="text-center">
                <h1 class="py-3">Phoenixgn Packages</h1>


            </div>
            <!--/ title text and switch button -->

            <!-- pricing plan cards -->


            <div class="row pricing-card">
                <div class="col-12 col-sm-offset-2 col-sm-10 col-md-12 col-lg-offset-2 col-lg-10 mx-auto">
                    <div class="row">
                        <!-- basic plan -->
                        <!-- {{userData.package_id}} -->
                        <div v-for="phoenixgn_package in phoenixgn_packages" :key="phoenixgn_package.id"
                            class="col-12 col-md-4">



                            <div
                                :class="'card basic-pricing text-center' + (userData.package_id == phoenixgn_package.id ? 'border border-success' : '')">

                                <div :class="'pricing-badge text-center'">
                                    <div
                                        :class="'badge badge-pill badge-light-success' + (userData.package_id == phoenixgn_package.id ? '' : 'd-none')">
                                        {{ userData.package_id == phoenixgn_package.id ? 'Subscribed' : '' }}</div>
                                </div>

                                <div class="card-body">
                                    <div class="text-center mx-auto">
                                        <img :src="phoenixgn_package.featured_logo" style="height: 150px;"
                                            class="mb-2" alt="svg img" />
                                        <h4 class="text-center">{{ phoenixgn_package.title }}</h4>
                                    </div>

                                    <div class="annual-plan">
                                        <div class="plan-price mt-2 text-center">
                                            <span style="font-size: 20pt;" class=" font-weight-bold text-white text-center">$
                                                {{ format(phoenixgn_package.reg_fee / 500) }}</span> <br>
                                                
                                            <!-- <sup class="font-medium-1 font-weight-bold text-white text-center">$ {{format(phoenixgn_package.reg_fee * 0.9/500)}}</sup> -->

                                            <span class="pricing-basic-value font-weight-bolder text-primary"></span>

                                        </div>
                                        <small class="annual-pricing d-none text-muted"></small>
                                    </div>
                                    <ul class="list-group list-group-circle text-left">
                                        <li class="list-group-item d-flex justify-content-between" style="font-size: 12pt;">Referral Commision:
                                            <span class="font-weight-bold badge badge-succes">$
                                                {{ format(phoenixgn_package.dr_commission / 500) }}</span>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between" style="font-size: 12pt;">Points:
                                            <span class="font-weight-bold badge badge-succes">{{ phoenixgn_package.points }}</span>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between" style="font-size: 12pt;">3 in 1 Commission:
                                            <span class="font-weight-bold badge badge-succes">{{ phoenixgn_package.three_one_commission }}</span>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between" style="font-size: 12pt;">Matching Bonus:
                                            <span class="font-weight-bold badge badge-succes">{{ phoenixgn_package.match_bonus }}</span>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between" style="font-size: 12pt;">Daily Matching:
                                            <span class="font-weight-bold badge badge-succes">{{ phoenixgn_package.max_daily_matching }}</span>
                                        </li>

                                    </ul>



                                    <div style="" class="form-group d-none">
                                        <input type="radio" class="form-control" name='package_id'
                                            @click="getPackageId(phoenixgn_package.id)" v-bind:value="phoenixgn_package.id"
                                            id="">
                                    </div>
                                    <div v-if="userData.package_id">

                                    <div v-if="userData.package_id == 1">

                                        
                                        <router-link
                                            v-if="phoenixgn_package.id == 8"
                                            :to="'/user/package/' + 8"
                                            :class="'btn btn-warning btn-block'">
                                            <i class="fas fa-turn-up"></i>
                                            Upgrade
                                        </router-link>

                                        

                                        
                                        
                                    </div>

                                    <div v-if="userData.package_id == 2">

                                        
                                    <router-link
                                        v-if="phoenixgn_package.id == 7"
                                      
                                        :to="'/user/package/' + 7"
                                        :class="'btn btn-warning btn-block'">
                                        <i class="fas fa-turn-up"></i>
                                        Upgrade
                                    </router-link>


                                    <router-link
                                        v-if="phoenixgn_package.id == 8"
                                      
                                        :to="'/user/package/' + 8"
                                        :class="'btn btn-warning btn-block'">
                                        <i class="fas fa-turn-up"></i>
                                        Upgrade
                                    </router-link>

                                   

                                    </div>

                                    <div v-esle>

                                        
                                        <router-link
                                            v-if="userData.package_id > phoenixgn_package.id"
                                            :to="'/user/package/' + phoenixgn_package.id"
                                            :class="'btn btn-warning btn-block'">
                                            <i class="fas fa-turn-up"></i>
                                            Upgrade
                                        </router-link>

                                        <router-link v-else :to="'/user/package/' + phoenixgn_package.id"
                                            :class="'btn btn-success btn-block d-none'">
                                            <i class="fas fa-money-bill-alt"></i>
                                            Make Payment
                                        </router-link>

                                    </div>




                                    </div>
                                    <div v-else>

                                        <router-link :to="'/user/package/' + phoenixgn_package.id"
                                        :class="'btn btn-success btn-block '">
                                        <i class="fas fa-money-bill-alt"></i>
                                        Make Payment .
                                    </router-link>

                                    </div>
                                    <!-- <router-link v-if="userData.package_id && userData.package_id >= phoenixgn_package.id"
                                        :to="'/user/package/' + phoenixgn_package.id" :class="'btn btn-warning btn-block'">
                                        <i class="fas fa-money-bill-alt"></i>
                                        Upgrade
                                    </router-link>

                                    <router-link v-else :to="'/user/package/' + phoenixgn_package.id"
                                        :class="'btn btn-success btn-block '">
                                        <i class="fas fa-money-bill-alt"></i>
                                        Make Payment
                                    </router-link> -->



                                </div>
                            </div>
                        </div>


                        <!--/ basic plan -->

                        <!--/ enterprise plan -->
                    </div>
                </div>




            </div>
            <!--/ pricing plan cards -->




        </section>


    </div>
</template>

<script>
import axios from 'axios'
// import paystack from "vue-paystack";

export default {
    components: {
        // paystack,
    },
    data() {
        return {
            phoenixgn_packages: [],
            paystackkey: "pk_test_5d9f4fab1166494f3cb4fb14af308a2e2345b7c0", //paystack public key
            email: "foobar@example.com", // Customer email
            amount: 1000000, // in kobo
            userData: [],
            package_id: '',
            selected_package_id: '',
            activating: false,
            payment_proof: '',
            payment_proof_data: ''
        }
    },



    methods: {




        format(value) {
            var numeral = require('numeral');

            return numeral(value).format('N 0,0.00')
        },


        getPackages() {
            let loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                canCancel: true,
                color: '#7367F0',
                loader: 'dots',
                onCancel: this.onCancel,
                opacity: 0
            });

            axios({
                method: "get",
                url: process.env.VUE_APP_URL + '/api/packages',
            })
                .then((response) => {

                    this.phoenixgn_packages = response.data
                    loader.hide()
                    console.log(response)
                })
                .catch((response) => {

                    console.log(response)
                })
        },
        getUserData() {
            this.userData = JSON.parse(localStorage.getItem('user_data'));
        },


    },
    mounted() {

        if (localStorage.getItem('pckreload')) {
            // The page was just reloaded. Clear the value from local storage
            // so that it will reload the next time this page is visited.
            localStorage.removeItem('pckreload');
        } else {
            // Set a flag so that we know not to reload the page twice.9989887
            localStorage.setItem('pckreload', '1');
            location.reload();
        }

        this.getPackages()
        this.getUserData()
        this.getUserProfile()

    },
}
</script>

<style>input[type="radio"]:checked~div {}</style>