<template>
  <div class="container card card-body">
    <!-- Navigation Tabs -->

    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Stair Steps</h4>
      </div>
      <div class="card-boy">
        <div id="carousel-example-caption" class="carousel slide" data-ride="carousel">
          <ol class="carousel-indicators">
            <li v-for="(level, index) in levels" :key="index" data-target="#carousel-example-caption"
              :data-slide-to="index" :class="index == 0 ? 'active' : ''"></li>

          </ol>
          <div class="carousel-inner" role="listbox">


            <div v-for="(level, index) in levels" :key="index"
              :class="index == 0 ? 'carousel-item active' : 'carousel-item'">

              <div class="carousel-captio card card-bod  text-black" style="color: black;">

                <div class="row mb-5">


                  <div class="col-sm-4">

                    <img style="height: 150px; width: 150px; object-fit: cover;" :src="'/stair_icons/' + level.icon"
                      alt="Level Icon" class="stair-logo shadow" />
                  </div>

                  <div class="col-sm-8 text-white">

                    <h3 class="text-white" style="color: black;">{{ level.name }}</h3>

                    <p>
                      {{ level.description }}
                    </p>

                  </div>

                </div>



                <div class="row mt-5 mb-5">


                  <!-- Left Group -->
                  <div class="col-md-6">
                    <h5 class="text-center">Left Group</h5>
                    <div class="progress">
                      <div class="progress-bar bg-primary shadow-sm" role="progressbar"
                        :style="{ width: calculateProgress(level.leftCount, level.leftMax) + '%' }"
                        :aria-valuenow="level.leftCount" :aria-valuemin="0" :aria-valuemax="level.leftMax">
                        {{ level.leftCount }}/{{ level.leftMax }}
                      </div>
                    </div>
                  </div>

                  <!-- Right Group -->
                  <div class="col-md-6">
                    <h5 class="text-center">Right Group</h5>
                    <div class="progress ">
                      <div class="progress-bar bg-primary shadow-sm" role="progressbar"
                        :style="{ width: calculateProgress(level.rightCount, level.rightMax) + '%' }"
                        :aria-valuenow="level.rightCount" :aria-valuemin="0" :aria-valuemax="level.rightMax">
                        {{ level.rightCount }}/{{ level.rightMax }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
          <a class="carousel-control-prev" href="#carousel-example-caption" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carousel-example-caption" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>
    <!-- <ul class="nav nav-pills mb-3 d-flex justify-content-between">
        <li v-for="(level, index) in levels" :key="index" class="nav-item">
          <a
            style="background-color:"
            :class="['nav-link', { active: index === 0 }]"
            data-toggle="pill"
            :href="'#' + level.name"
          >
            {{ level.name }}
          </a>
        </li>
      </ul> -->

    <!-- Tab Content -->
    <div class="tab-content">
      <!-- <div
          v-for="(level, index) in levels"
          :key="index"
          :id="level.name"
          :class="['tab-pane', { active: index === 0 }]"
        >
          <div class="row">
          
            <div class="col-12 text-center mb-3">
              <img :src="'/stair_icons/' + level.icon" alt="Level Icon" class="stair-logo" />
            </div>
  
            <div class="col-md-6">
              <h5 class="text-center">Left Group</h5>
              <div class="progress">
                <div
                  class="progress-bar bg-primary shadow-sm"
                  role="progressbar"
                  :style="{ width: calculateProgress(level.leftCount, level.leftMax) + '%' }"
                  :aria-valuenow="level.leftCount"
                  :aria-valuemin="0"
                  :aria-valuemax="level.leftMax"
                >
                  {{ level.leftCount }}/{{ level.leftMax }}
                </div>
              </div>
            </div>
  
            <div class="col-md-6">
              <h5 class="text-center">Right Group</h5>
              <div class="progress">
                <div
                  class="progress-bar bg-primary shadow-sm"
                  role="progressbar"
                  :style="{ width: calculateProgress(level.rightCount, level.rightMax) + '%' }"
                  :aria-valuenow="level.rightCount"
                  :aria-valuemin="0"
                  :aria-valuemax="level.rightMax"
                >
                  {{ level.rightCount }}/{{ level.rightMax }}
                </div>
              </div>
            </div>
          </div>
  
          <div class="text-center mt-3">
            <router-link to="/user/earnings" class="btn btn-primary">
              View Details
            </router-link>
          </div>
        </div> -->
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {

  data() {
    return {
      levels: [
        {
          name: 'Jade Status',
          description: 'Register 5 Sapphire Packages on your left and another 5 Sapphire Packages on your right group and earn 5% the packages',
          icon: 'jade_status.png',
          color: 'red',
          leftCount: 0,
          leftMax: 5,
          rightCount: 0,
          rightMax: 5,
        },
        {
          name: 'Opal Status',
          description: 'Register 10 Ruby Packages on your left and another 10 Ruby Packages on your right group and earn 5% of the packages',
          icon: 'opal_status.png',
          leftCount: 0,
          leftMax: 10,
          rightCount: 0,
          rightMax: 10,
        },
        {
          name: 'Onyx Status',
          description: 'Register 20 Gold Packages on your left and another 20 Gold Packages on your right group and earn 5% of the packages',
          icon: 'onyx_status.png',
          leftCount: 0,
          leftMax: 20,
          rightCount: 0,
          rightMax: 20,
        },
        {
          name: 'Zircon Status',
          description: 'Register 30 Diamond Packages on your left and another 30 Diamond Packages on your right group and earn 5% of the packages',
          icon: 'zircon_status.png',
          leftCount: 0,
          leftMax: 30,
          rightCount: 0,
          rightMax: 30,
        },
        {
          name: 'Director Status',
          description: 'Register 50 Diamond Pro-Max Packages on your left and another 50 Diamond Pro-Max Packages on your right group and earn 5% of the packages',
          icon: 'director_status.png',
          leftCount: 0,
          leftMax: 50,
          rightCount: 0,
          rightMax: 50,
        },
        {
          name: 'Diamond Ambassador Status',
          description: 'Register 50 Family Packages on your left and another 50 Family Packages on your right group and earn 5% of the total worth of the Packages',
          icon: 'diamond_amb_status.png',
          leftCount: 0,
          leftMax: 50,
          rightCount: 0,
          rightMax: 50,
        },
        {
          name: 'Global Diamond Ambassador Status',
          description: 'Among the 50 Family Packages on your left and right group that took you to Diamond Ambassador Status, Pick the 6th Family Package on your left and right group and develop them to achieve and become a Diamond Ambassador, then you will achieve and become Global Diamond Ambassador 1-Star and earn 8% of the total worth of the Packages',
          icon: 'global_diamond_amb_status.png',
          leftCount: 0,
          leftMax: 50,
          rightCount: 0,
          rightMax: 50,
        }
      ],
    };
  },
  mounted() {
    this.getStairStepData();
  },
  methods: {
    calculateProgress(count, max) {
      return Math.min((count / max) * 100, 100).toFixed(2);
    },

    updateLevels(responseData) {
      // Convert the backend data into a map for quick lookup
      const stairStepMap = new Map();
      responseData.stairStepData.forEach(step => {
        stairStepMap.set(step.stair_step_type, step);
      });

      // Update the frontend levels array
      this.levels = this.levels.map(level => {
        if (stairStepMap.has(level.name)) {
          const backendData = stairStepMap.get(level.name);
          return {
            ...level,
            leftCount: backendData.left_count,
            rightCount: backendData.right_count,
          };
        }
        return level;
      });
    },


    getStairStepData() {
      axios({
        url: process.env.VUE_APP_URL + '/api/stair-step-data',
        method: 'get',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user_token')
        }
      })
        .then((response) => {
          if (response.data && response.data.stairStepData) {
            this.updateLevels(response.data);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }

  },


};
</script>

<style scoped>
.stair-logo {
  width: 100px;
  height: auto;
}

.progress {
  height: 25px;
}
</style>